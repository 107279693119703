<template>
  <div class="que-bank-system">
    <ContactInfo></ContactInfo>
    <div class="one-div">
      <div>
        <p v-scroll="'animate__bounceInDown'" class="animate__animated">
          简单好用/多专业实时协同设计/拥有独立的数据库
        </p>
        <div class="bottom">
          <el-button
            class="animate__animated"
            v-scroll="'animate__fadeInDown'"
            @click="$router.push('./trial-use')"
            style="background: #255ca2; color: white; border: 1px solid #255ca2"
            >联系我们</el-button
          >
        </div>
      </div>
      <div class="text animate__animated" v-scroll="'animate__fadeIn'">
        景翔首创<span>“题库体系”</span>，我们采用了先进的算法技术将企业现有的管理类文件转换为一种更直观更易操作的<span>“落地格式”。</span>这一体系分为部门和个人（岗位）文件落地检查题库，解决了员工难以全面掌握所需的文件总量和哪些内容与自己有关的难题，也就是排除了文件难以落地的关键障碍。实践表明，题库体系比管理体系文件更直接、更有效！
      </div>
    </div>
    <div class="tow-div">
      <div class="bigimg">
        <img
          class="animate__animated"
          v-scroll="'animate__fadeIn'"
          src="@assets/images/QueBankBag3.png"
          alt=""
        />
        <div class="title">
          <p class="animate__animated" v-scroll="'animate__fadeInDown'">
            落地检查题库特点
          </p>
          <p></p>
        </div>
      </div>
      <div class="fivebox">
        <div
          class="animate__animated"
          v-scroll="'animate__rollIn'"
          v-for="(item, index) in featureList"
          :key="index"
          :style="{ background: item.index == 1 ? '#F6F6F6' : '#4874CB' }"
        >
          <img :src="item.img" />
          <p
            class="p1"
            :style="{ color: item.index == 1 ? '#4678C4' : '#FFFFFF' }"
          >
            {{ item.p1 }}
          </p>
          <p
            class="p2"
            :style="{ color: item.index == 1 ? '#939393' : '#FFFFFF' }"
          >
            {{ item.p2 }}
          </p>
        </div>
      </div>
    </div>
    <div class="three-div animate__animated" v-scroll="'animate__bounceInLeft'">
      <img src="@assets/images/QueBankSystem7.png" alt="" />
      <div class="title">
        <p>题库体系构成</p>
        <p></p>
      </div>
      <div class="div-box">
        <div class="one">
          <span
            >根据ISO 9001、ISO
            14001、ISO45001、IATF16949等常规标准生成的题库</span
          >
          <span>标准通用题库</span>
        </div>
        <div class="tow">
          <span
            >针对特定管理主题（特种行业、特种设备、风险）专门开发的题库</span
          >
          <span>专题题库 </span>
        </div>
        <div class="three">
          <span>汇总内外部审核中发现的问题点转化为重点问题检查题库</span>
          <span>内外审问题点题库</span>
        </div>
        <span class="title2 animate__animated" v-scroll="'animate__flipInY'"
          >题库体系构成</span
        >
        <div class="four">
          <span
            >针对现场特点专门建立的5S、工艺纪律、安全、环境因素、信息安全等题库。</span
          >
          <span>例行工作检查题库</span>
        </div>
        <div class="five">
          <span>根据企业的体系文件转换的题库</span>
          <span>体系文件落地检查题库</span>
        </div>
        <div class="six">
          <span>用于应知应会、业务、培训的测试题库</span>
          <span>测试题库</span>
        </div>
      </div>
    </div>
    <div class="four-div">
      <img src="@assets/images/QueBankSystem8.png" alt="" />
      <div class="title animate__animated" v-scroll="'animate__bounceIn'">
        <p>题库能解决什么问题？</p>
        <p></p>
      </div>
      <div class="question-box">
        <div class="one animate__animated" v-scroll="'animate__bounceInLeft'">
          <p>自建成本高昂</p>
          <span>自行建立和维护题库需要大量的人力和时间成本</span>
        </div>
        <div class="tow animate__animated" v-scroll="'animate__bounceInLeft'">
          <p>管理要求分散</p>
          <span>企业管理要求往往散布在多个文件中，难以快速查找和执行。</span>
        </div>
        <div class="three animate__animated" v-scroll="'animate__bounceInDown'">
          <p>检查标准不一致</p>
          <span>确保内审和日常各类工作检查的标准化和一致性</span>
        </div>
        <div class="four animate__animated" v-scroll="'animate__bounceInRight'">
          <p>培训效率低下</p>
          <span>提供个性化学习路径，提升员工学习效率和质量</span>
        </div>
        <div class="five animate__animated" v-scroll="'animate__bounceInRight'">
          <p>落地执行难度大</p>
          <span>将管理标准和要求转化为实际可执行的操作存在困难。</span>
        </div>
      </div>
    </div>
    <div class="five-div">
      <div class="title animate__animated" v-scroll="'animate__flipInY'">
        <p>题库体系建立</p>
      </div>
      <div class="tab animate__animated" v-scroll="'animate__fadeIn'">
        <span
          v-for="(item, index) in carouselDiv.tab"
          :key="index"
          @click="clickCarouselTab(index)"
          :class="{ carouselActive: index == carouseltabIndex }"
          >{{ item }}</span
        >
      </div>

      <div>
        <div class="carousel-img">
          <el-carousel
            class="animate__animated"
            v-scroll="'animate__fadeInDownBig'"
            trigger="click"
            height="480px"
            ref="carouse"
            @change="changeCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in carouselDiv.content"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="animate__animated" v-scroll="'animate__fadeInUpBig'">
            <p class="p1">{{ consultItem.p1 }}</p>
            <p class="p2">
              {{ consultItem.p2 }}
            </p>
            <span class="CustomButton" @click="$router.push('./trial-use')"
              >免费使用</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="six-div">
      <div class="title animate__animated" v-scroll="'animate__slideInRight'">
        <p>产品之外的增值服务</p>
        <p></p>
      </div>
      <div class="box">
        <div
          class="animate__animated"
          v-scroll="'animate__rollIn'"
          v-for="(item, index) in serviceBox"
          :key="index"
          :style="{
            width: item.width,
            background: item.background,
          }"
        >
          <img class="icon" :src="item.img" alt="" />
          <p class="sixp1">{{ item.p1 }}</p>
          <p class="sixp2">{{ item.p2 }}</p>
          <img class="bag" src="../../../assets/images/bag.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="seven-div">
      <img
        class="map animate__animated"
        v-scroll="'animate__fadeInRightBig'"
        src="@assets/images/QueBankSystem19.png"
        alt=""
      />
      <div class="center">
        <div class="title animate__animated" v-scroll="'animate__bounceIn'">
          <p>联系我们</p>
          <p></p>
        </div>
        <img
          class="animate__animated"
          v-scroll="'animate__fadeInRightBig'"
          src="@assets/images/QueBankSystem22.png"
          alt=""
        />
        <p class="p2 animate__animated" v-scroll="'animate__fadeIn'">
          您也可以在线留言联系我们 <br />我们会尽快与您联系~
        </p>
        <el-button class="animate__animated" v-scroll="'animate__fadeIn'"
          >在线留言</el-button
        >
      </div>
      <div class="right animate__animated" v-scroll="'animate__fadeInRightBig'">
        <p>微信</p>
        <img src="@assets/images/QueBankSystem23.png" alt="" />
      </div>
    </div> -->
    <div class="contact-us">
      <div class="phone animate__animated" v-scroll="'animate__fadeIn'">
        <div>
          <p>联系我们</p>
          <p>CONTACT US</p>
        </div>
        <div>
          <span>服务热线：</span>
          <span>020-39984322</span>
        </div>
      </div>

      <div class="other-info animate__animated" v-scroll="'animate__bounceIn'">
        <div>
          <p>地址：广州市番禺区洛溪新城105国道97号东基商务大楼6楼</p>
          <p>电话：020-39984322 / 39984311</p>
          <p>传真：020-39984300</p>
          <p class="infoBox">
            <img src="@assets/images/logo1.png" alt="" />
            <span @click="$router.push('/home-page')"
              >景翔信息科技有限公司</span
            >
            <a href="http://beian.miit.gov.cn" target="_blank"
              >(粤ICP备2021114949号-4)</a
            >
          </p>
        </div>

        <div>
          <p>手机：13560068664（微信同号）</p>
          <p>E-mail：mail@jeansawn.com</p>
          <p>网址：www.jeansawn.com</p>
          <p>©2024 广州市景翔信息科技有限公司&nbsp;&nbsp;版权所有</p>
        </div>
        <div class="jpg">
          <img src="@assets/newhome/jingxiang.jpg" alt="" />
          <p>扫一扫关注我们</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
import animateScroll from "../../../modules/AnimateScroll.js";
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      featureList: [
        {
          img: require("@assets/images/QueBankSystem6.png"),
          p1: "文件萃取",
          p2: "将庞大和复杂的文件体系分解给部门各个岗位，便于员工执行",
          index: 1,
        },
        {
          img: require("@assets/images/QueBankSystem2.png"),
          p1: "专业性强",
          p2: "将各种各样的文件格式和表达方式转化到统一的“落地格式”上，需要很强的专业性。",
          index: 2,
        },
        {
          img: require("@assets/images/QueBankSystem3.png"),
          p1: "知识萃取",
          p2: "题库体系涵盖了文件体系的全部内容，可以转化为“岗位学习内容”、“测试题”和“应知应会”。",
          index: 1,
        },
        {
          img: require("@assets/images/QueBankSystem4.png"),
          p1: "持续更新",
          p2: "题库可以根据体系的变化进行实时更新，确保与最新的标准和要求保持一致",
          index: 2,
        },
        {
          img: require("@assets/images/QueBankSystem5.png"),
          p1: "高效检查",
          p2: "题库可以自动生成内审检查表、工作检查表，降低审核员和检查人员的专业门槛",
          index: 1,
        },
      ],
      carouseltabIndex: 0,
      carouselDiv: {
        tab: ["题库转换", "云体系", "常规审核", "学习测试"],
        content: [
          {
            img: require("@assets/images/QueBankSystem9.png"),
            p1: "1、文件到题库的转换",
            p2: "题库体系建立是一项非常专业的工作，且工作量大，企业自身难以胜任，企业可用很低的成本委托我公司承担这项工作。",
          },
          {
            img: require("@assets/images/QueBankSystem10.png"),
            p1: "2、题库体系导入云体系平台",
            p2: "通过平台系统的审核体系、日常检查工作、学习中心、专项检查模块实现题库的智能提取与应用",
          },
          {
            img: require("@assets/images/QueBankSystem11.png"),
            p1: "3、常规标准审核界面系平台。",
            p2: "可以抽取标准体系题库，也可以抽取企业自有题库，生成问题检查表和审核计划",
          },
          {
            img: require("@assets/images/QueBankSystem12.png"),
            p1: "4、学习和业务测评界面系平台。",
            p2: "基于不同类型的题库，都可以快速的针对不同岗位、不同职能生成个性化学习和业务测试问卷",
          },
        ],
      },
      serviceBox: [
        {
          width: "28%",
          background: "#EAEEF8",
          p1: "例行工作检查服务",
          p2: "可以委托我们的专业团队做5S、安全、工艺纪律、特殊工种等例行现场工作检查",
          img: require("@assets/images/QueBankSystem13.png"),
        },
        {
          width: "18%",
          background: "#4874CB",
          p1: "委托体系审核",
          p2: "委托我们的专业团队进行内部审核",
          img: require("@assets/images/QueBankSystem14.png"),
        },
        {
          width: "18%",
          background: "#5C87DC",
          p1: "定制化解决方案",
          p2: "根据客户需求提供个性化题库建立和管理服务，确保最佳使用体验",
          img: require("@assets/images/QueBankSystem15.png"),
        },
        {
          width: "18%",
          background: "#6893E9",
          p1: "持续更新与维护",
          p2: "保证题库内容的及时更新和系统的稳定运行",
          img: require("@assets/images/QueBankSystem16.png"),
        },
        {
          width: "18%",
          background: "#719DF4",
          p1: "客户支持",
          p2: "提供24/7（全天候、不间断）在线客服，随时解答疑问",
          img: require("@assets/images/QueBankSystem17.png"),
        },
      ],
      setInterval: null,
      consultItem: {},
    };
  },

  animateScroll,
  mounted() {
    this.changeCarousel(0);
  },
  methods: {
    clickCarouselTab(index) {
      this.carouseltabIndex = index;
      this.$refs.carouse.setActiveItem(index);
    },
    changeCarousel(index) {
      this.carouseltabIndex = index;
      this.consultItem = this.carouselDiv.content[index];
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/FontSize.css";
@media screen and (min-width: 1200px) and (max-width: 1519px) {
  .que-bank-system {
    .one-div {
      .text {
        font-size: 15px !important;
        margin-top: 5% !important;
      }
    }
    .tow-div {
      .bigimg {
        margin-top: 5%;
        img {
          width: 360px !important;
          height: 210px !important;
        }
      }

      .title p {
        font-size: 30px !important;
      }

      .fivebox {
        img {
          width: 35px !important;
          height: 35px !important;
        }
        .p1 {
          font-size: 20px !important;
        }
        .p2 {
          font-size: 14px !important;
        }
      }
    }
    .three-div,
    .four-div {
      .title p {
        font-size: 30px !important;
      }
    }
    .six-div {
      .title p {
        font-size: 30px;
      }
      .sixp1 {
        font-size: 18px !important;
      }
      .sixp2 {
        font-size: 13px !important;
      }
    }

    .five-div {
      .title {
        p {
          font-size: 30px !important;
        }
      }
      .carousel-img {
        .el-carousel {
          width: 45% !important;
          height: 450px !important;
        }
        .p1 {
          font-size: 18px !important;
        }
      }
    }
    .seven-div {
      .title {
        p {
          font-size: 30px !important;
        }
      }
      .map {
        width: 500px !important;
      }
      .center {
        > img {
          width: 180px !important;
          margin-top: -30px;
        }
        .p2 {
          font-size: 13px !important;
          margin-top: 10px !important;
        }
      }
      .right {
        img {
          width: 180px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .que-bank-system {
    .one-div {
      p {
        font-size: 13px;
      }
      .text {
        font-size: 12px !important;
        margin-top: 4% !important;
      }
    }
    .tow-div {
      .bigimg {
        margin-top: 10%;
        img {
          width: 250px !important;
          height: 150px !important;
        }
      }
      .title {
        p:nth-of-type(1) {
          font-size: 24px !important;
        }
        p:nth-of-type(2) {
          width: 30px !important;
          height: 4px !important;
        }
      }
      .fivebox {
        img {
          width: 28px !important;
          height: 28px !important;
        }
        .p1 {
          font-size: 16px !important;
        }
        .p2 {
          font-size: 12px !important;
        }
      }
    }
    .three-div,
    .four-div {
      .title p {
        font-size: 24px !important;
      }
    }
    .five-div {
      margin-bottom: -50px !important;
      padding-top: 50px !important;
      .title {
        p {
          font-size: 24px !important;
        }
      }
      .tab span {
        font-size: 12px !important;
      }
      .carousel-img {
        .el-carousel {
          width: 40% !important;
          height: 380px !important;
        }
        .p1 {
          font-size: 16px !important;
        }
        .p2 {
          font-size: 12px !important;
          line-height: 16px !important;
        }
      }
    }
    .six-div {
      .title p {
        font-size: 24px !important;
      }
      .sixp1 {
        font-size: 16px !important;
      }
      .sixp2 {
        font-size: 12px !important;
      }
    }
    .seven-div {
      .title {
        p {
          font-size: 24px !important;
        }
      }
      .map {
        width: 400px !important;
      }
      .center {
        > img {
          width: 140px !important;
          margin-top: -10px !important;
        }
        .p2 {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
      }
      .right {
        img {
          width: 140px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .que-bank-system {
    .tow-div {
      .bigimg {
        margin-top: 20% !important;
        img {
          width: 200px !important;
          height: 120px !important;
          margin-right: 20px !important;
        }
      }

      .title {
        p:nth-of-type(1) {
          font-size: 16px !important;
        }
        p:nth-of-type(2) {
          width: 20px !important;
          height: 2px !important;
        }
      }
      .fivebox {
        img {
          width: 20px !important;
          height: 20px !important;
        }
        .p1 {
          font-size: 12px !important;
        }
        .p2 {
          font-size: 8px !important;
        }
      }
    }
    .six-div {
      p {
        font-size: 22px !important;
      }
      .sixp1 {
        font-size: 12px !important;
      }
      .sixp2 {
        font-size: 8px !important;
      }
    }
  }
}
.que-bank-system {
  font-size: 14px;
  .title {
    position: relative;
    p:nth-of-type(1) {
      font-size: 38px;
    }
    p:nth-of-type(2) {
      position: absolute;
      width: 52px;
      height: 7px;
      background: #4375c8;
      border-radius: 5px;
      margin-top: 20px;
    }
  }

  .one-div {
    position: relative;
    height: 100vh;
    background: url("../../../assets/images/queBankSystem1.png");
    background-repeat: no-repeat;
    background-size: 100%;
    p {
      color: #24569c;
      padding-top: 14%;
      padding-left: 15%;
    }
    .bottom {
      padding-top: 1%;
      padding-left: 15%;
      /deep/ .el-button {
        padding: 22px 30px !important;
        line-height: 0px !important;
        border-radius: 10px !important;
        margin-right: 10px;
        font-weight: bold;
        font-size: 16px !important;
      }
    }
    .text {
      margin-top: 6%;
      background: white;
      height: 130px;
      padding: 40px 5%;
      font-size: 18px;
      line-height: 32px;
      border-radius: 20px;
      background: #eff4f8;
      box-shadow: 0px 0px 6px -2px #a6a6a6;
      opacity: 0.8;
      cursor: default;
      width: 70%;
      margin-left: 10%;
      span {
        color: #195099;
        cursor: default;
      }
    }
  }
  .tow-div {
    height: 100vh;
    background: #ffffff;
    padding: 0 11%;
    padding-top: 50px;
    .bigimg {
      display: flex;
      align-items: center;
      img {
        width: 450px;
        height: 280px;
        border-radius: 20px;
        margin-right: 90px;
      }
      .title {
        margin-top: -60px;
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .fivebox {
      display: flex;
      justify-content: space-between;
      margin-top: 45px;
      div:hover {
        .p1 {
          transform: scale(1.25);
        }
      }
      div {
        width: 160px;
        text-align: center;
        padding: 35px 20px 20px;
        border-radius: 15px;
        img {
          width: 35px;
          height: 35px;
        }
        .p1 {
          font-size: 23px;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .p2 {
          line-height: 25px;
          font-size: 16px;
          text-align: start;
        }
      }
    }
  }
  .three-div {
    position: relative;
    img {
      max-width: 100%;
      width: auto 0;
    }
    .title {
      position: absolute;
      top: 10%;
      margin: 0 10%;
      // padding-top: 10vh;
      font-weight: 400;
      font-size: 0.48rem;
      color: #3c3c3c;
      line-height: 0.6rem;
      text-shadow: 0px 1px 1px rgba(140, 172, 209, 0.59);
    }
    .title2 {
      position: absolute;
      width: 1.82rem;
      height: 0.3rem;
      font-weight: bold;
      font-size: 0.3rem;
      color: #ffffff;
      line-height: 0.36rem;
      bottom: 40.52%;
      left: 44.85%;
      cursor: default;
    }
    .div-box {
      & > div {
        span {
          position: absolute;
          cursor: default;
        }
        span:nth-of-type(1) {
          font-weight: 400;
          font-size: 0.22rem;
          color: #333333;
        }
        span:nth-of-type(2) {
          font-weight: bold;
          font-size: 0.2rem;
          color: #ffffff;
        }
      }
      .one {
        span:nth-of-type(1) {
          width: 3.04rem;
          height: 0.95rem;
          top: 26.25%;
          left: 18.31%;
        }
        span:nth-of-type(2) {
          width: 1.2rem;
          height: 0.2rem;
          top: 33.13%;
          left: 35.15%;
        }
      }
      .tow {
        span:nth-of-type(1) {
          width: 3.3rem;
          height: 0.59rem;
          top: 50.73%;
          left: 12.08%;
        }
        span:nth-of-type(2) {
          width: 0.8rem;
          height: 0.2rem;
          top: 56.35%;
          left: 31.96%;
        }
      }
      .three {
        span:nth-of-type(1) {
          width: 2.86rem;
          height: 0.58rem;
          bottom: 20.94%;
          left: 20.72%;
        }
        span:nth-of-type(2) {
          text-align: center;
          width: 1.18rem;
          height: 0.57rem;
          bottom: 16.46%;
          left: 37.35%;
        }
      }
      .four {
        span:nth-of-type(1) {
          width: 3.85rem;
          height: 0.58rem;
          top: 17.29%;
          right: 24.06%;
        }
        span:nth-of-type(2) {
          text-align: center;
          width: 1.2rem;
          height: 0.56rem;
          top: 22.29%;
          left: 47.65%;
        }
      }
      .five {
        span:nth-of-type(1) {
          width: 1.98rem;
          height: 0.58rem;
          top: 39.06%;
          right: 20.71%;
        }
        span:nth-of-type(2) {
          text-align: center;
          width: 1.2rem;
          height: 0.56rem;
          top: 43.96%;
          left: 60.85%;
        }
      }
      .six {
        span:nth-of-type(1) {
          width: 2.86rem;
          height: 0.58rem;
          top: 68.13%;
          right: 19.48%;
        }
        span:nth-of-type(2) {
          text-align: center;
          width: 0.8rem;
          height: 0.2rem;
          top: 73.96%;
          right: 37.18%;
        }
      }
    }
  }
  @media screen and (min-width: 1030px) and (max-width: 1150px) {
    .title2 {
      font-size: 18px !important;
    }
    .div-box {
      & > div {
        span:nth-of-type(1) {
          width: 2.2rem !important;
          font-size: 14px !important;
        }
        span:nth-of-type(2) {
          font-size: 12px !important;
        }
      }
      .four {
        span:nth-of-type(1) {
          right: 28.06% !important;
        }
      }
      .five {
        span:nth-of-type(1) {
          right: 15.71% !important;
        }
      }
    }
  }
  @media screen and (min-width: 831px) and (max-width: 1029px) {
    .title2 {
      font-size: 14px !important;
    }
    .div-box {
      & > div {
        span:nth-of-type(1) {
          width: 2rem !important;
          font-size: 12px !important;
        }
        span:nth-of-type(2) {
          width: 0.9rem !important;
          font-size: 0.16rem !important;
          text-align: center;
        }
      }
      .one {
        span:nth-of-type(2) {
          top: 31.13% !important;
        }
      }
      .tow {
        span:nth-of-type(2) {
          top: 55.35% !important;
          left: 30.96% !important;
        }
      }
      .three {
        span:nth-of-type(2) {
          bottom: 15.46% !important;
        }
      }
      .four {
        span:nth-of-type(1) {
          right: 27.06% !important;
        }
        span:nth-of-type(2) {
          top: 21.29% !important;
        }
      }
      .five {
        span:nth-of-type(1) {
          right: 14.71% !important;
        }
        span:nth-of-type(2) {
          top: 42.96% !important;
          left: 61% !important;
        }
      }
      .six {
        span:nth-of-type(1) {
          right: 18.48% !important;
        }
        span:nth-of-type(2) {
          top: 72.96% !important;
          right: 36.18% !important;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 830px) {
    .title2 {
      font-size: 14px !important;
    }
    .div-box {
      & > div {
        span:nth-of-type(1) {
          width: 2rem !important;
          font-size: 12px !important;
        }
        span:nth-of-type(2) {
          width: 0.8rem !important;
          font-size: 0.16rem !important;
          text-align: center;
        }
      }
      .one {
        span:nth-of-type(2) {
          top: 31.13% !important;
        }
      }
      .tow {
        span:nth-of-type(2) {
          top: 55.35% !important;
          left: 30.96% !important;
        }
      }
      .three {
        span:nth-of-type(2) {
          bottom: 15.46% !important;
        }
      }
      .four {
        span:nth-of-type(1) {
          right: 27.06% !important;
        }
        span:nth-of-type(2) {
          top: 21.29% !important;
        }
      }
      .five {
        span:nth-of-type(1) {
          right: 14.71% !important;
        }
        span:nth-of-type(2) {
          top: 42.96% !important;
        }
      }
      .six {
        span:nth-of-type(1) {
          right: 18.48% !important;
        }
        span:nth-of-type(2) {
          top: 72.96% !important;
          right: 36.18% !important;
        }
      }
    }
  }

  .four-div {
    position: relative;
    margin-top: -5px;
    img {
      max-width: 100%;
      width: auto 0;
    }
    .title {
      position: absolute;
      top: 10%;
      margin: 0 10%;
      color: white;
      font-weight: 400;
      font-size: 0.48rem;
      p:nth-of-type(2) {
        background: white;
        font-size: 0.3rem;
        line-height: 0.36rem;
      }
    }
    .question-box {
      > div {
        position: absolute;
        text-align: end;
        display: flex;
        flex-direction: column;
        p {
          width: 2.5rem;
          font-size: 0.28rem;
          color: #1f3d82;
          height: 0.3rem;
          background-color: #dfecf2;
          border-radius: 50%;
          line-height: 3px;
          text-align: center;
          font-weight: bold;
          cursor: default;
        }
        span {
          font-size: 0.18rem;
          width: 3.04rem;
          display: inline-block;
          line-height: 23px;
          margin-top: 10px;
          color: #333333;
          cursor: default;
        }
      }
      .one {
        top: 68%;
        left: 35%;
        align-items: end;
      }
      .tow {
        top: 32%;
        left: 33.5%;
        align-items: end;
      }
      .three {
        align-items: start;
        top: 18%;
        left: 60%;
        span {
          text-align: start;
        }
      }
      .four {
        top: 45%;
        left: 74.5%;
        align-items: start;

        span {
          text-align: start;
        }
      }
      .five {
        top: 75%;
        left: 70.5%;
        align-items: start;
        span {
          text-align: start;
        }
      }
    }
  }

  .five-div {
    width: 100%;
    height: 100vh;
    text-align: center;
    background: #f8f9ff;
    margin-top: -4px;
    .title {
      padding-top: 5vh;
    }
    .tab {
      margin-top: 30px;
      background: white;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 20px;
      span {
        margin: 0 10px;
        padding: 3px 15px;
        border-radius: 20px;
        color: #666666;
      }
      .carouselActive {
        color: white !important;
        background: linear-gradient(to right, #4077da, #9caee4);
      }
    }
    .carousel-img {
      display: flex;
      justify-content: center;
      .el-carousel {
        width: 48%;
        margin-top: 20px;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      > div {
        margin-left: 3.5%;
        margin-top: 10vh;
        text-align: start;
        width: 20%;
        p:nth-of-type(1) {
          font-size: 20px;
          padding-bottom: 20px;
        }
        p:nth-of-type(2) {
          line-height: 25px;
        }
      }
      .CustomButton {
        margin-top: 25px;
      }
    }
  }
  .six-div {
    width: 100%;
    height: 100vh;
    position: relative;
    .title {
      padding: 10vh 0 19vh 10%;
      background-color: white;
    }
    .box {
      display: flex;
      align-items: end;
      position: absolute;
      bottom: 0;
      div {
        position: relative;
        text-align: center;
        height: 50vh;
        .icon {
          margin-top: 60px;
          width: 50px;
        }
        p {
          color: white;
          padding-top: 25px;
        }
        p:nth-of-type(1) {
          font-size: 25px;
          margin: 0 10%;
        }
        p:nth-of-type(2) {
          position: relative;
          line-height: 25px;
          margin: 0 12%;
          z-index: 99999;
        }
        .bag {
          width: 100%;
          height: 40%;
          position: absolute;
          bottom: 0;
          left: 0;
          display: none;
        }
      }
      div:nth-of-type(1) {
        p {
          color: #4874cb;
          margin: 0 25%;
        }
      }
      div:nth-of-type(1):hover {
        .bag {
          height: 60% !important;
        }
      }
      div:hover {
        height: 75vh;
        .bag {
          display: block;
        }
      }
    }
  }
  .callandmap {
    height: 100vh;
    // margin: 0 10%;
    text-align: center;
    margin-top: 20px;
    .call {
      text-align: center;
      margin-bottom: 20px;

      p:nth-of-type(1) {
        display: inline-block;
        position: relative;
        font-size: 24px;
        font-weight: bold;
        color: #434343;
        margin-bottom: 10px;
        padding-top: 20px;
      }
      p:nth-of-type(1):after {
        content: "";
        position: absolute;
        background-image: url(../../../assets/images/bag4.png);
        background-repeat: no-repeat;
        bottom: -18px;
        right: -10px;
        width: 115px;
        height: 10px;
        background-size: 100%;
      }
      p:nth-of-type(2) {
        margin-top: 25px;
      }
    }
  }
  .seven-div {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background: #ffffff;
    .map {
      width: 600px;
      margin-left: 10%;
    }
    .center {
      position: relative;
      margin-left: 60px;
      .title {
        margin-bottom: 60px;
      }
      > img {
        width: 200px;
      }
      // .p1 {
      //   margin-top: 20px;
      //   font-size: 16px;
      //   display: flex;
      //   align-items: center;
      //   background: #f3f3f3;
      //   padding: 10px;
      //   img {
      //     width: 15px;
      //   }
      // }
      .p2 {
        font-size: 14px;
        color: #999999;
        margin-top: 50px;
        // position: absolute;
        // bottom: 50px;
      }
      .el-button {
        // position: absolute;
        // bottom: 0px;
        margin-top: 20px;
        background: #255ca2;
        color: white;
        height: 40px !important;
        width: 120px !important;
        border-radius: 10px !important;
        font-weight: bold;
        font-size: 13px !important;
      }
    }
    .right {
      margin-top: -60px;
      margin-left: 2%;
      img {
        width: 200px;
        margin-top: 10px;
      }
    }
  }
  .contact-us {
    background: #222c3f;
    color: white;
    padding: 20px 10%;
    flex: 1;
    .phone {
      padding-bottom: 5px;
      border-bottom: 1px solid #50769c;
      font-size: 15px;
      span {
        cursor: default;
      }
      > div:nth-of-type(2) {
        width: 100%;
        text-align: end;
      }
    }
    .other-info {
      display: flex;
      margin-top: 20px;
      font-size: 14.5px;
      > div:nth-of-type(1) {
        flex: 2;
      }
      > div:nth-of-type(2) {
        flex: 1;
      }
      > .jpg {
        text-align: end;
        flex: 1;
        img {
          margin-right: 15px;
          width: 70px;
          height: 70px;
          margin-bottom: 8px;
        }
      }
      .infoBox {
        margin-top: 6px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }
        a {
          color: skyblue;
        }
      }
    }
  }
}
</style>
